import React, { useCallback, useEffect } from 'react';
import { ContainerTable } from '../../styles';
import CustomDataGrid from '../../MyComponents/CustomDataGrid';
import { useBuscaNfe } from '../../BuscaNfeContext';

import { InputNumber2, InputPercent } from '~/components/NovosInputs';
import {
  formatCurrencyAsTex3,
  formatCurrencyAsText,
  moneyFormat,
  transformAsCurrency,
} from '~/utils/functions';
import { variacao } from '../../utils/variacao';
import { NFItemColumns } from './NFItemColumns';
import useDebounce from '~/hooks/useDebounce';
import { formatToCurrency } from '~/pages/AlteracaoDePrecoPorItem/utils/formatToCurrency';

export const TableNFItem: React.FC = () => {
  const { nfItens, setNfItens, formItens, nfXMLItens, calcCustoRepNF } =
    useBuscaNfe();
  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = formItens;

  const { debouncedFn } = useDebounce();

  useEffect(() => {
    nfItens.forEach((item) => {
      const fieldName = `qtd_emb_modal_${item.cod_produto}_${item.num_item}`;
      setValue(fieldName, formatCurrencyAsText(item.qtd_embalagem));
    });
  }, [nfItens, setValue]);

  const updateFields = useCallback(
    (
      fieldName: string,
      value: string,
      cod_produto: string,
      num_item: number,
    ) => {
      const currency = formatToCurrency(value);
      const newValue = transformAsCurrency(currency);
      setValue(fieldName, formatToCurrency(value));

      const prod = nfItens.find(
        (item) =>
          item.cod_produto === cod_produto && item.num_item === num_item,
      );
      if (prod) {
        const xmlItem = nfXMLItens.itens[0].find(
          (item: any) => Number(item.nItem) === Number(prod.num_item),
        );

        let vUnidade: string = xmlItem.uCom;

        if (prod.flg_ipv && xmlItem.uCom !== 'KG' && xmlItem.uTrib === 'KG') {
          vUnidade = xmlItem.uTrib;
        }

        const custoRepNF = calcCustoRepNF({
          val_peso: prod.val_peso,
          vUnidade,
          vQtde: prod.qtd_item,
          qtd_embalagem: newValue,
          xmlItem,
        });

        const variacaoPercent = variacao(
          transformAsCurrency(prod.val_custo_rep),
          custoRepNF === Infinity ? 0 : custoRepNF,
        );
        prod.qtd_total = prod.qtd_item * newValue;
        prod.qtd_embalagem = currency;
        prod.val_custo_rep_nf =
          custoRepNF === Infinity ? '0,00' : formatCurrencyAsText(custoRepNF);
        prod.variacao_percent =
          variacaoPercent === Infinity
            ? '0,00'
            : formatCurrencyAsText(variacaoPercent);

        const productsRest = nfItens.filter(
          (item) =>
            item.cod_produto !== cod_produto || item.num_item !== num_item,
        );
        setNfItens(
          [...productsRest, prod].sort((a, b) => a.num_item - b.num_item),
        );
      }
    },
    [setValue, nfItens, nfXMLItens, calcCustoRepNF, setNfItens],
  );

  const renderCell = (row: any) => {
    const fieldName = `qtd_emb_modal_${row.cod_produto}_${row.num_item}`;
    return (
      <InputPercent
        label=""
        placeholder=""
        maxLength={7}
        max={65535}
        min={0}
        control={control}
        name={fieldName}
        register={register}
        showIcon={false}
        isError={!!errors[fieldName]}
        style={{ marginTop: -36, marginBottom: -8 }}
        qtdDecimais={2}
        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        //   setValue(fieldName, event.target.value);
        // }}
        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
          updateFields(
            fieldName,
            event.target.value,
            row.cod_produto,
            row.num_item,
          );
        }}
      />
    );
  };

  const qtds = [
    {
      field: 'qtd_item',
      headerName: 'Qtd.',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { qtd_item } = params;
        return <>{formatCurrencyAsTex3(qtd_item)}</>;
      },
    },
    {
      field: 'qtd_total',
      headerName: 'Qtd. Total',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { qtd_total } = params;
        return <>{formatCurrencyAsTex3(qtd_total)}</>;
      },
    },
  ];

  const values = [
    {
      field: 'val_total_item',
      headerName: 'Val Total',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { val_total_item } = params;
        return <>{val_total_item}</>;
      },
    },
    {
      field: 'val_custo_rep',
      headerName: 'C.Rep. Lj',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { val_custo_rep } = params;
        return <>{val_custo_rep}</>;
      },
    },
    {
      field: 'val_custo_rep_nf',
      headerName: 'C.Rep. NF',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { val_custo_rep_nf } = params;
        return <>{formatCurrencyAsText(val_custo_rep_nf)}</>;
      },
    },
    {
      field: 'variacao_percent',
      headerName: '% Variação',
      fixed: false,
      width: 70,
      renderCell: (params: any) => {
        const { variacao_percent } = params;
        const percent =
          variacao_percent || variacao_percent === 0
            ? variacao_percent
            : '0,00';
        return <>{percent}</>;
      },
    },
  ];

  const action = {
    field: 'qtd_embalagem',
    headerName: 'Emb',
    fixed: false,
    width: 120,
    renderCell,
  };

  const columns = [...NFItemColumns, action, ...qtds, ...values];
  return (
    <ContainerTable style={{ maxHeight: '320px' }}>
      <CustomDataGrid columns={columns} rows={nfItens} isPaginated={false} />
    </ContainerTable>
  );
};
